// import function to register Swiper custom elements
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, EffectFade } from "swiper/modules";

// register Swiper custom elements
// register();
document.addEventListener("DOMContentLoaded", () => {
    new Swiper(".swiper-landing", {
        modules: [Autoplay, EffectFade],
        height: 1334,
        slidesPerView: 1,
        speed: 1500,
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        loop: true,
        lazy: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });

    new Swiper(".swiper-footer", {
        modules: [Autoplay, EffectFade],
        slidesPerView: 1,
        speed: 1500,
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        lazy: true,
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
    });

    const images = new Swiper(".swiper-images", {
        modules: [Autoplay],
        slidesPerView: 3,
        speed: 1200,
        spaceBetween: 0,
        loop: true,
        lazy: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 0,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 0,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 0,
            },
        },
    });

    const nextEl = document.querySelector(".p-next");
    const prevEl = document.querySelector(".p-prev");
    const paginationEl = document.querySelector(".p-pagination");
    const slider = new Swiper(".swiper-programme", {
        modules: [Pagination, Navigation],
        navigation: {
            nextEl,
            prevEl,
        },
        // initialSlide: 1,
        // centeredSlides: true,
        // centeredSlidesBounds: true,
        // loop: true,
        pagination: {
            el: paginationEl,
            //     el: ".pagination-custom",
            //     clickable: true,
            //     bulletElement: "div",
            //     bulletClass: "bullet-active",
            //     renderBullet: function (index, className) {
            //         let currentSlide = document.querySelector(
            //             `[data-swiper-slide="${index}"]`
            //         );
            //         console.log(currentSlide.dataset.day);
            //         // date.classList.add(className);
            //         return `<div data-swiper-slide="0" class="slide-indicator ${className}"><span>${currentSlide.dataset.day}</span><span class="text-5xl">${currentSlide.dataset.date}</span></div>`;
            //         // '<span class="' + className + '">' + (index + 1) + "</span>"
            //         // date
            //         // );
            //     },
        },
        // autoplay: {
        //     delay: 5000,
        // },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1280: {
                slidesPerView: 4,
                spaceBetween: 50,
            },
        },
    });

    function unmarkIndicator(params) {
        document.querySelectorAll(".slide-indicator").forEach((node) => {
            node.classList.remove("active");
        });
    }

    slider.on("slideChange", function (e) {
        const activeSlide = e.slides[e.activeIndex].dataset.date;

        unmarkIndicator();
        const selectDay = document.querySelector(
            `.slide-indicator[data-goto="${activeSlide}"]`
        );
        selectDay.classList.add("active");
    });

    document.querySelectorAll(".slide-indicator").forEach((e) => {
        let selectedDate = e.dataset.goto;
        e.addEventListener("click", (evt) => {
            unmarkIndicator();
            e.classList.add("active");
            let selectedSlide = document.querySelector(
                `.swiper-slide[data-date="${selectedDate}"]`
            );
            let slideTo = Array.prototype.indexOf.call(
                document.getElementsByClassName("swiper-programme-wrapper")[0]
                    .children,
                selectedSlide
            );
            slider.slideTo(slideTo);
        });
    });

    const sliderNews = new Swiper(".swiper-news", {
        modules: [Navigation],
        // navigation: {
        //     nextEl,
        //     prevEl,
        // },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50,
            },
        },
    });
});
